import { LoadingContainer } from './styles';

const Loading = () => {

    return (
        <LoadingContainer>
            <div className="lds-dual-ring"></div>
        </LoadingContainer>
    );
};

export default Loading;